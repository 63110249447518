

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.form-group{
  width:100%;
}
.DefaultBtn{
  width: 100%;
  background: #007066;
  display: block;
  color: #fff;
}
.linkBtn{
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: none;
}
.validationError{
  display: block;
  text-align: left;
  margin: 10px 0 0 0;
  color: #f00;
}

.loadingSec{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.50);
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #008973;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  right: 0;
  left: 50%;
  bottom: 0;
  margin-left: -30px;
  margin-top: -30px;
}

.formWidget {
  width: 50% !important;
}
figcaption a{
  color: #4a4a4a;
  text-decoration: none;
  
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 767px){
  .formWidget {
      width: 94% !important;
      transform: none !important;
  }
}

